import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Analytics from 'src/utils/Analytics'
import StoreContext from 'src/context/StoreContext'
import LoadingAnimation from 'src/components/LoadingAnimation'
import { PRODUCTS } from './constants'

class AddAndRedirect extends React.Component {
  componentDidMount() {
    const { addToCart } = this.context
    const { products, redirectTo } = this.props
    const lineItems = []
    products.forEach((key) => {
      const { eventLabel, variantId } = PRODUCTS[key]
      Analytics.track(eventLabel)
      lineItems.push({ variantId, quantity: 1 })
    })
    addToCart(lineItems).then(() => navigate(redirectTo))
  }

  render() {
    return <LoadingAnimation />
  }
}

AddAndRedirect.contextType = StoreContext

AddAndRedirect.propTypes = {
  products: PropTypes.array.isRequired,
  redirectTo: PropTypes.string.isRequired,
}

export default AddAndRedirect
