import React from 'react'

import loadingGif from 'src/gifs/loading.gif'
import { CenteredContent, ContentContainer } from 'src/utils/styles/global-styles'

const LoadingAnimation = () => (
  <ContentContainer>
    <CenteredContent>
      <img src={loadingGif} alt="Loading..." />
    </CenteredContent>
  </ContentContainer>
)

export default LoadingAnimation
