import React from 'react'

import AddAndRedirect from 'src/components/AddAndRedirect'
import { PLUS_BUY_LINK } from 'src/utils/constants'

const AddFrameThenUpsell = () => (
  <>
    <AddAndRedirect products={['frame']} redirectTo={PLUS_BUY_LINK} />
  </>
)

export default AddFrameThenUpsell
