export const PRODUCTS = {
  plus: {
    variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC85NTUwMjkzOTI1OTQw',
    eventLabel: 'Added Plus to Cart',
  },
  frame: {
    variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC84ODk5MzMyNTA1NjUy',
    eventLabel: 'Added Frame to Cart',
  },
}
